import * as factory from "library";
import { format18 } from "../utils";

export const graphReducer = (state, action) => {
  let parent;
  let maxX = 0;
  let maxY = 0;
  let arrayIds = [];
  let arrayData = [];
  let elementPort = 0;
  let element;
  let rawData;
  let limitX;
  let elementAsset;
  console.log(action.type, action)

  switch (action.type) {
    case "ADD_INPUT":
      element = factory.getElements()[`INPUT_${action.addElement.asset}`];
      state.addElement(element, action.addElement.limit, 0);
      return state;
    case "ADD_FLASH_SWAP":
      element = factory.getElements()[
        `FLASH_SWAP_IN_${action.addElement.asset}`
      ];
      state.addElement(element, action.addElement.limit, 0);
      return state;

    case "ADD_OPERATION":
      element = factory.getElements()[action.addElement.selectedElement.key];

      for (const par of action.addElement.parents) {
        parent = state.getElementById(par);

        if (parent.index[0] > maxX) {
          maxX = parent.index[0];
        }
        if (parent.index[1] > maxY) {
          maxY = parent.index[1];
        }

        if (
          parent.type == "SplitterElement" &&
          parent.connections.length == 1
        ) {
          elementPort = parent.connections.length;
          maxX = action.addElement.limit;
        }

        for (const i of Object.keys(
          action.addElement.selectedElement.executionData
        )) {
          if (
            action.addElement.selectedElement.executionData[i].type ==
              "input" &&
            action.addElement.data[i]
          ) {
            rawData = action.addElement.data[i];
            if (action.addElement.selectedElement.key.includes("0x")) {
              rawData = JSON.parse(action.addElement.data[i]);
            }
            arrayData.push({
              index: i,
              value: rawData,
            });
          }
        }

        const asset = parent.outputs[0];
        const inputIndex = element.inputs.indexOf(asset);

        arrayIds.push([par, elementPort, inputIndex]);
      }

      //Add element to graph
      state.connectElements(arrayIds, element, maxX, maxY + 1, arrayData);

      return state;

    case "ADD_SPLITTER":
      // console.log("ADD_SPLITTER", action);
      for (const par of action.addElement.parents) {
        parent = state.getElementById(par);

        if (parent.index[0] > maxX) {
          maxX = parent.index[0];
        }
        if (parent.index[1] > maxY) {
          maxY = parent.index[1];
        }

        arrayIds.push([par, elementPort, 0]);
      }

      for (const i of Object.keys(
        action.addElement.selectedElement.executionData
      )) {
        if (
          action.addElement.selectedElement.executionData[i].type == "input" &&
          action.addElement.data[i]
        ) {
          // console.log('adding data for index: ', i, action.addElement.data[i])
          arrayData.push({
            index: i,
            value: action.addElement.data[i],
          });
        }
      }

      element = factory.getElements()[action.addElement.element.key];
      state.connectElements(arrayIds, element, maxX, maxY + 1, arrayData);
      return state;

    case "ADD_OUTPUT":
      // console.log("ADD_ADDRESS", action);
      element = factory.getElements()[action.addElement.selectedElement.key];

      for (const par of action.addElement.parents) {
        parent = state.getElementById(par);

        if (parent.index[0] > maxX) {
          maxX = parent.index[0];
        }
        if (parent.index[1] > maxY) {
          maxY = parent.index[1];
        }

        if (
          parent.type == "SplitterElement" &&
          parent.connections.length == 1
        ) {
          elementPort = parent.connections.length;
          limitX = action.addElement.limit;
          //FIXME this is a workaround for limit now updating
          if (action.addElement.limit == parent.index[0]) {
            limitX = action.addElement.limit + 1;
          }
          maxX = limitX;
          // console.log("New Max ", maxX);
        }

        const asset = parent.outputs[0];
        const inputIndex = element.inputs.indexOf(asset);

        arrayIds.push([par, elementPort, inputIndex]);
        // console.log(arrayIds, element, asset, inputIndex);
      }

      for (const i of Object.keys(
        action.addElement.selectedElement.executionData
      )) {
        if (
          action.addElement.selectedElement.executionData[i].type == "input" &&
          action.addElement.data[i]
        ) {
          arrayData.push({
            index: i,
            value: action.addElement.data[i],
          });
        }
      }

      state.connectElements(arrayIds, element, maxX, maxY + 1, arrayData);

      return state;

    case "LOAD_GRAPH":
      return action.lg;

    case "ADD_DATA":

      elementAsset = factory.getElements()[
        action.addElement.selectedElement.key
      ].outputs[0];

      console.log('KAKAKA',action.addElement.selectedElement.key)


      for (const i of Object.keys(
        action.addElement.selectedElement.executionData
      )) {
        if (
          action.addElement.selectedElement.executionData[i].type == "input" &&
          action.addElement.data[i]
        ) {

          //FIXME problem with address
          rawData = !action.addElement.data[i].includes('0x') && format18(elementAsset, action.addElement.data[i]);
          // is 0x?
          if (action.addElement.selectedElement.key.includes("0x")) {
            rawData = JSON.parse(action.addElement.data[i]);
          }
          
          // is oasis?
          if (action.addElement.selectedElement.key.includes("OASIS")) {
            rawData = action.addElement.data[i];
          }
           // is ADDRESS?
           if (action.addElement.selectedElement.key.includes("ADDRESS")) {
            rawData = action.addElement.data[i];
          }
          state.setExecutionData(
            action.addElement.selectedElement.id,
            i,
            rawData
          );
        }
      }
      return state;

    case "CLEAR_STACK":
      return [];

    default:
      throw new Error();
  }
};
