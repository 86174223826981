import React from "react";

export default function NotFoundPage() {
  return (
    <div>
      <div>OO404OOPSS 🤷‍♂️</div>
      <div>Not found.</div>
    </div>
  );
}
