export const EMPTY_ELEMENT = {
  id: "EmptyElement007",
  type: "EmptyElement",
  address: "",
  addreinput: "",
  output: "",
  connections: [],
  index: [],
};

export const NEW_INIT_ELEMENT = {
  id: "NewInitStack",
  type: "NewInitStack",
  address: "",
  addreinput: "",
  inputs: [],
  output: [],
  connections: [],
  index: [],
};

export const ASSETS_NAMES = {
  "0x0000000000000000000000000000000000000000": "ETH",
  "0x6B175474E89094C44Da98b954EedeAC495271d0F": "DAI",
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": "WETH",
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": "USDC",
  "0xdAC17F958D2ee523a2206206994597C13D831ec7": "USDT",
  "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51": "SUSD",
  "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599": "WBTC",
  "0x1985365e9f78359a9B6AD760e32412f4a445E862": "REP",
  "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643": "CDAI",
  "0x39AA39c021dfbaE8faC545936693aC917d5E7563": "CUSDC",
  "0xC11b1268C1A384e55C48c2391d8d480264A3A7F4": "CWBTC",
  "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5": "CETH",
};

export const ASSETS_COLORS = {
  "0x0000000000000000000000000000000000000000": "#565656",
  "0x6B175474E89094C44Da98b954EedeAC495271d0F": "#f6ac37",
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": "#565656",
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": "#2c77ce",
  "0xdAC17F958D2ee523a2206206994597C13D831ec7": "#4daa90",
  "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51": "#1d1a31",
  "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599": "#f6941a",
  "0x1985365e9f78359a9B6AD760e32412f4a445E862": "#5d294f",
  "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643": "#5194c0",
  "0x39AA39c021dfbaE8faC545936693aC917d5E7563": "#3fba92",
  "0xC11b1268C1A384e55C48c2391d8d480264A3A7F4": "#f6941a",
  "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5": "#5194c0",
};

export const AVAILABLE_SWAPS = {
  "0x6B175474E89094C44Da98b954EedeAC495271d0F": "DAI",
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": "WETH",
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": "USDC",
  "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599": "WBTC",
};




export const ELEMENTS_MAX_OPERATIONS = {
  'SplitterElement': 2,
  'OperationElement': 1,
  'NewInitStack': 1,
  'InputElement': 1,
  'EmptyElement': 1
};

export const ELEMENTS_OFFSET_MAP = {
  'SplitterElement': 1,
  'OperationElement': 1,
  'NewInitStack': 0,
  'InputElement': 1,
  'EmptyElement': 1
};
